#band-overlay-chart {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 100%; */
    height: 100%;
    /* opacity: 10%; */
    /* cursor: pointer; */
    transition: opacity .1s ease-in-out;
}

#band {
    cursor: pointer;
    transition: opacity .1s ease-in-out;
    opacity: 10%;
    touch-action: none;
}

#band-overlay-chart:hover #band {
    opacity: 20%;
}

#band-overlay-chart:hover .band-edge-line, 
#band-overlay-chart:hover .band-edge-handle {
    opacity: 50%;
}

#band:hover ~ .band-edge-line, #band:hover ~ .band-edge-handle {
    opacity: 70%;
}

#band:hover ~ .band-edge-line {
    stroke-width: 2px;
}

.band-edge:hover ~ .band-edge-handle {
    opacity: 0%;
}

.band-edge {
    cursor: col-resize;
    opacity: 0%;
    touch-action: none;
}

.band-edge-line, .band-edge-handle {
    opacity: 25%;
    transition: all .1s ease-in-out;
    touch-action: none;
}

#band-edge-left:hover ~ #band-edge-line-left,
#band-edge-right:hover ~ #band-edge-line-right{
    opacity: 100%;
    stroke-width: 4px;
}

.band-edge-line, .band-edge-handle {
    pointer-events: none;
}

#band:hover ~ .band-edge-line{
    opacity: 50%;
}

.power-button-box:hover {
    cursor: pointer;
}

.power-button:hover {
    stroke-width: 3;
}