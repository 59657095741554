.details-summary-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: auto;
    cursor: pointer;
    font-weight: 500;
}

.custom-details-wrapper {
    width: 80%;
}