#theme-icon-wrapper {
    cursor: pointer;
}

.theme-icon {
    transition: all .25s ease-in-out;
}

.theme-icon:hover {
    filter: drop-shadow(0px 0px 2px goldenrod);
}