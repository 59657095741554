.preset-button {
    cursor: pointer;
    background-color: var(--container-highlight);
    min-width: fit-content;
    width: 100%;
    /* width: calc(100% - 50px); */
    height: 80px;
    padding: .5em;
    color: var(--text-primary);
    border: none;
    flex-shrink: 1;
}

#preset-info-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--overlay);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-content: center;
}

.preset-button:hover {
    background-color: var(--container-back);
}

.preset-button:active {
    background-color: var(--container-fore);
}

.preset-info-wrapper {
    width: fit-content;
    max-width: min(600px, 90vw);
    height: fit-content;
    max-height: 50vh;
    padding: 2em;
    background-color: var(--container-fore);
    border: 1px solid var(--border);
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto 150px;
    cursor: default;
    justify-self: center;
    align-self: center;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.preset-summary-text {
    grid-row: 1;
    grid-column: 1;
    justify-self: center;
    align-self: center;
    margin: 1em;
}

.preset-details-text {
    grid-row: 2;
    grid-column: 1 / 3;
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    text-align: left;
}

.preset-summary-text h2 {
    margin: .5em;
}

a {
    color: var(--fill-color);
}

.preset-image {
    grid-row: 1;
    grid-column: 2;
    display: inline-block;
    width: 150px;
    height: 150px;
    overflow: clip;
    border: 1px solid var(--border);
    align-self: center;
}

.preset-wrapper {
    display: flex;
    transition: all 1s ease-in-out;
    border: 1px solid var(--border);
    height: 80px;
    flex-shrink: 0;
}

.preset-info-button {
    background-color: var(--container-mid);
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-left: 1px dotted var(--border);
    cursor: pointer;
}

.preset-info-button:hover {
    background-color: var(--container-back);
}

.preset-info-button:active {
    background-color: var(--container-shadow);
}

.sound-source-icon {
    fill: var(--text-default);
    height: 30px;
    width: 30px;
}