.chart-and-axes {
    display: grid;
    grid-template-columns: 40px 1fr auto;
    grid-template-rows: auto 40px;
    width: calc(100vw - 125px);
    max-width: calc(100vw - 125px);
    height: calc(70vh - 30px);
    min-height: 200px;
}

.yAxis {
    grid-column: 1;
    width: 100%;
    height: 100%;
}

.xAxis {
    grid-column: 2;
    grid-row: 2;
    width: 100%;
    height: 100%;
}

#charts-and-menu-wrapper {
    display: flex;
    position: relative;
    /* TODO: Hardcoding this size dependent on axis size is not great. 
    This is a workaround for how Safari chooses to handle relative grid 
    dimensions. Seek a solution */
    height: calc(70vh - 70px);
    min-height: 200px;
    box-sizing: border-box;
    width: 100%;
    grid-row: 1;
    grid-column: 2;
    border: 2px solid var(--border);
    background-color: var(--container-shadow);
    overflow: clip;
    box-shadow: 0px 0px 8px .1px var(--container-shadow);
    border-radius: 0 5px 5px 0;
}

.canvases {
    position: relative;
}

.audio-charts {
    /* flex-shrink: 1; */
    /* position: relative; */
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    background-color: var(--container-shadow);
}